<template>
	<div class="page_container">
		<div class="page_head">
			<div class="back" @click="goBack">
				<a-icon type="left-circle" :style="{ fontSize: '24px', color: '#c4d9f3' }" />
			</div>
			<div class="title">用一句话为网红生成创意图</div>
		</div>
		<div class="page_main">
			<div class="canvas_box" :class="{ notright: loading || createData.length }">
				<div class="img_content">
					<div class="loading" v-if="loading">
						<img src="../assets/imgs/loading.png" alt="" />
						<p>正在创作中，请您耐心等待。。。</p>
					</div>
					<div v-else>
						<div class="img" v-if="createData.length"
							:style="{ transform: `scale(${scale})`, width: createImageSize.w + 'px', height: createImageSize.h + 'px' }">
							<img :src="createData[createActiveIndex].img" alt="" />
						</div>
						<div class="default" v-else>
							<a-icon type="picture" theme="filled" :style="{ fontSize: '40px' }" />
							<p>等待绘画中</p>
						</div>
					</div>
				</div>
				<div class="img_list" :class="{ show: createData.length || loading }">
					<div class="list" v-if="createData.length">
						<div class="item" :class="{ active: createActiveIndex == index }"
							v-for="(item, index) in createData" :key="'create_' + item.id"
							@click="changeCurrentImage(index)">
							<img :src="item.img" alt="" />
						</div>
					</div>
					<div class="list" v-if="loading">
						<div class="item" v-for="(item, index) in countActiveValue" :key="'loading_' + item">
							<a-spin />
						</div>
					</div>
				</div>
				<div class="img_tools" v-if="createData.length && !loading">
					<a-tooltip placement="right">
						<template slot="title">
							<span>收藏</span>
						</template>
						<div class="item" @click="collectHandle">
							<a-icon type="heart" theme="filled"
								:style="{ fontSize: '16px', color: createData[createActiveIndex].collect ? '#FF3434' : '' }" />
						</div>
					</a-tooltip>
					<a-tooltip placement="right">
						<template slot="title">
							<span>下载</span>
						</template>
						<div class="item" @click="downloadHandle">
							<a-icon type="download" />
						</div>
					</a-tooltip>
					<a-tooltip placement="right">
						<template slot="title">
							<span>放大</span>
						</template>
						<div class="item" @click="scaleHandle(0.1)">
							<a-icon type="zoom-in" />
						</div>
					</a-tooltip>
					<a-tooltip placement="right">
						<template slot="title">
							<span>缩小</span>
						</template>
						<div class="item" @click="scaleHandle(-0.1)">
							<a-icon type="zoom-out" />
						</div>
					</a-tooltip>
				</div>
			</div>
			<div class="config_box">
				<div class="mask_box" v-if="loading"></div>
				<div class="params_box">
					<div class="label_box">
						<div class="label">
							<span>绘画描述</span>
							<div class="key_word">
								<span :class="{ active: keywordActiveIndex == index }" v-for="(item, index) in keywordData"
									:key="'keyword_' + index" @click="changeCurrentKeyword(index)">{{ item }}</span>
								<span @click="refreshKeyword">
									<a-icon type="sync" :spin="keywordUpdate" />
								</span>
							</div>
						</div>
						<div class="desc">
							<div class="desc_input">
								<a-textarea v-model="descValue" placeholder="请输入绘画的描述词汇" :auto-size="{ minRows: 4 }" />
							</div>
							<div class="desc_content">
								<div class="item" :class="{ active: keywordTemplateActiveIndex == index }"
									v-for="(item, index) in keywordTemplateData" :key="'keywordTemplate_' + index"
									@click="; (keywordTemplateActiveIndex = index), (descValue = item)">{{ item }}</div>
							</div>
							<div class="desc_button" @click="refreshKeywordTemplate">
								<a-icon type="sync" :spin="keywordTemplateUpdate" />
								<span>换一换</span>
							</div>
						</div>
					</div>
					<div class="label_box">
						<div class="label">画布比例</div>
						<div class="list size">
							<div class="item" :class="{ active: sizeActive == item.id }" v-for="(item, index) in sizeData"
								:key="'size_' + item.id" @click="sizeActive = item.id">
								<div class="graph" :style="legendSize(item)"></div>
								<div class="text">{{ item.value }}</div>
							</div>
						</div>
					</div>
					<div class="label_box">
						<div class="label">定义风格</div>
						<div class="list style">
							<div class="item" :class="{ active: styleActive == item.id }" v-for="(item, index) in styleData"
								:key="'style_' + item.id" @click="styleActive = item.id">
								<div class="img">
									<img :src="item.img" alt="" />
									<p class="c-line-1">{{ item.value }}</p>
								</div>
							</div>
						</div>
					</div>
					<div class="label_box">
						<div class="label">艺术家</div>
						<div class="list fillet">
							<div class="item" :class="{ active: artistActive == item.id }"
								v-for="(item, index) in artistData" :key="'artist_' + item.id"
								@click="artistActive = item.id">
								<div class="c-line-1">{{ item.value }}</div>
							</div>
						</div>
					</div>
					<div class="label_box">
						<div class="label">装饰词</div>
						<div class="list fillet">
							<div class="item" :class="{ active: item.checked }" v-for="(item, index) in bedeckData"
								:key="'bedeck_' + item.id" @click="item.checked = !item.checked">
								<div class="c-line-1">{{ item.value }}</div>
							</div>
						</div>
					</div>
					<div class="label_box">
						<div class="label">图片数量</div>
						<div class="list count">
							<div class="item" :class="{ active: countActive == item.id }" v-for="(item, index) in countData"
								:key="'count_' + item.id" @click="countActive = item.id">
								<div class="text">{{ item.value }}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="button_box">
					<!-- :loading="loading" -->
					<a-button type="primary" class="button" :loading="loading" @click="submitHandle">立即生成</a-button>
					<!-- <a-button type="primary" class="button" v-show="loading">生成中(300s)</a-button> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { create, all } from "mathjs"
const math = create(all, {
	number: "BigNumber",
	precision: 20
})
import { downloadPicture, sizePicture } from "@/utils/tools"
export default {
	data() {
		return {
			// 画布比例
			sizeData: [],
			sizeActive: 0,
			// 风格
			styleData: [{ id: 0, value: "不限定", img: require("@assets/imgs/style_default.png") }],
			styleActive: 0,
			// 艺术家
			artistData: [{ id: 0, value: "不限定" }],
			artistActive: 0,
			// 装饰词
			bedeckData: [],
			// 图片数量
			countData: [],
			countActive: 0,
			countActiveValue: 0,
			// 生成图片
			createData: [],
			createActiveIndex: 0,
			// 预览图缩放
			scale: 1,
			// 提交状态
			loading: false,
			// 描述词
			descValue: "",
			// 绘图任务ID
			drawingTaskId: "",
			// 定时器
			timerTask: null,
			// 关键词
			keywordData: [],
			keywordUpdate: false,
			keywordActiveIndex: 0,
			// 关键词模板
			keywordTemplateData: [],
			keywordTemplateUpdate: false,
			keywordTemplateActiveIndex: null,
			prompt_id: this.$route.query.id,
			flag: true
		}
	},
	computed: {
		// 图例尺寸
		legendSize() {
			return item => {
				const size = sizePicture(true, item.w, item.h, 20)
				return {
					width: size.w + "px",
					height: size.h + "px"
				}
			}
		},
		// 预览图尺寸
		createImageSize() {
			return sizePicture(false, this.createData[this.createActiveIndex].w, this.createData[this.createActiveIndex].h, 350)
		}
	},
	created() {
		this.getDrawingConfig()
		this.getKeyword()
	},
	mounted() { },
	beforeDestroy() {
		clearInterval(this.timerTask)
	},
	methods: {
		// 刷新关键词
		refreshKeyword() {
			this.keywordActiveIndex = 0
			this.keywordTemplateActiveIndex = null
			this.keywordUpdate = true
			this.getKeyword()
		},
		// 刷新关键词模板
		refreshKeywordTemplate() {
			this.keywordTemplateActiveIndex = null
			this.keywordTemplateUpdate = true
			this.getKeywordTemplate()
		},
		// 更改当前关键词
		changeCurrentKeyword(index) {
			this.keywordActiveIndex = index
			this.keywordTemplateActiveIndex = null
			this.getKeywordTemplate()
		},
		// 获取描述关键词
		getKeyword() {
			this.keywordData = []
			this.$http("drawing.keyword")
				.then(res => {
					if (res.code === 1) {
						this.keywordData = res.data
						this.getKeywordTemplate()
					}
					this.keywordUpdate = false
				})
				.catch(err => {
					this.keywordUpdate = false
				})
		},
		// 获取描述关键词模板
		getKeywordTemplate() {
			const kw = this.keywordData[this.keywordActiveIndex]
			this.keywordTemplateData = []
			this.$http("drawing.template", { key_word: kw })
				.then(res => {
					if (res.code === 1) {
						this.keywordTemplateData = res.data
					}
					this.keywordTemplateUpdate = false
				})
				.catch(err => {
					this.keywordTemplateUpdate = false
				})
		},
		// 获取绘画配置
		getDrawingConfig() {
			this.$http("drawing.config", { prompt_id: this.prompt_id }).then(res => {
				if (res.code === 1) {
					res.data.scale.length && ((this.sizeData = res.data.scale.map(({ id, name }) => ({ id, value: name, w: Number(name.split(":")[0]), h: Number(name.split(":")[1]) }))), (this.sizeActive = this.sizeData[0].id))
					res.data.style.length && (this.styleData = this.styleData.concat(res.data.style.map(({ id, image, name }) => ({ id, img: image, value: name }))))
					res.data.artists.length && (this.artistData = this.artistData.concat(res.data.artists.map(({ id, name }) => ({ id, value: name }))))
					res.data.magic_words.length && (this.bedeckData = res.data.magic_words.map(({ id, name }) => ({ id, value: name, checked: false })))
					// res.data.pic_size.length && ((this.countData = res.data.pic_size.map(({ id, name }) => ({ id, value: name }))), (this.countActive = this.countData[0].id))

					if (res.data.pic_size.length) {
						this.countData = res.data.pic_size.map(({ id, name }) => ({ id, value: name }))
						const arr = this.countData.filter(item => item.value == 4)
						arr && arr.length && (this.countActive = arr[0].id)
					}
				}
			})
		},
		// 提交
		submitHandle() {
			if (!this.sizeActive || !this.countActive) {
				Promise.reject("画布比例或图片数量ID错误 => 异常拦截")
				return
			}
			if (!this.descValue) {
				this.$message.warning("请输入作品的描述词汇")
				return
			}
			const arr = this.bedeckData.filter(item => item.checked == true).map(item => item.id)
			this.countActiveValue = Number(this.countData.filter(item => item.id == this.countActive)[0].value)
			const obj = {
				prompt_id: this.prompt_id,
				des: this.descValue,
				neg_prompt: "",
				style: this.styleActive,
				artists: this.artistActive,
				magic_words: arr.toString() || "",
				pic_size: this.countActive,
				scale: this.sizeActive
			}
			console.log(obj, "obj")
			this.loading = true
			this.flag = false
			this.$http("drawing.create", obj).then(res => {
				if (res.code === 1) {
					this.drawingTaskId = res.data.task_id
					this.timerInterval()
				} else {
					this.loading = false
					this.$message.error("生成图片失败！请刷新页面后重试。")
					this.flag = true
				}
			})
		},
		// 定时任务
		timerInterval() {
			this.timerTask = setInterval(() => {
				setTimeout(() => {
					if (this.loading) this.getDrawingResult()
					else clearInterval(this.timerTask)
				}, 0)
			}, 5000)
		},
		// 绘画结果
		getDrawingResult() {
			this.$http("drawing.result", { task_id: this.drawingTaskId }).then(res => {
				if (res.code === 1) {
					if (res.data) {
						if (res.data.length) {
							this.createData = this.createData.concat(res.data.map(({ id, image, width, height }) => ({ id, img: image, w: width, h: height, collect: false })))
						} else {
							this.$message.error("很抱歉，查询结果为空。")
						}
						this.loading = false
					}
					this.flag = true
				} else {
					this.loading = false
					this.flag = true
					this.$message.error("生成图片失败！请刷新页面后重试。")
				}
			})
		},
		// 改变当前预览图片
		changeCurrentImage(index) {
			this.createActiveIndex = index
			this.scale = 1
		},
		// 返回上一页
		goBack() {
			this.$router.back()
			clearInterval(this.timerTask)
		},
		// 下载
		downloadHandle() {
			this.createData[this.createActiveIndex].img && downloadPicture(this.createData[this.createActiveIndex].img)
		},
		// 缩放
		scaleHandle(value) {
			const number = +math.format(math.add(math.bignumber(this.scale), math.bignumber(value)))
			if (number > 2 || number < 0.5) return
			this.scale = number
		},
		// 收藏
		collectHandle() {
			this.createData[this.createActiveIndex].collect = !this.createData[this.createActiveIndex].collect
			this.$message.warning("正在开发中，敬请期待")
		}
	}
}
</script>

<style lang="scss" scoped>
.page_container {
	background: #222c3f;
	color: #c4d9f3;
	width: 100%;
	height: 100vh;
	overflow: hidden;
	display: flex;
	flex-direction: column;

	.page_head {
		height: 60px;
		display: flex;
		align-items: center;
		border-bottom: 1px solid #000;

		.back {
			width: 60px;
			height: 60px;
			border-right: 1px solid #000;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;

			&:hover {
				background: #31415d;
			}
		}

		.title {
			font-size: 14px;
			margin-left: 10px;
		}
	}

	.page_main {
		flex: 1;
		display: flex;
		flex-wrap: nowrap;
		overflow: hidden;
		position: relative;

		.canvas_box {
			width: calc(100% - 500px);
			height: 100%;
			background: #222c3f;
			border-right: 1px solid #000;
			padding: 20px;
			display: flex;
			flex-wrap: nowrap;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;

			&.notright {
				padding-right: 0;
			}

			.img_tools {
				width: 42px;
				height: calc(100% - 40px);
				position: absolute;
				top: 40px;
				left: 30px;
				display: flex;
				flex-direction: column;
				align-items: center;

				-webkit-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;

				.item {
					width: 32px;
					height: 32px;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 50%;
					background: #222c3f;
					color: #b7c9df;
					cursor: pointer;
					font-size: 18px;
					margin-bottom: 15px;

					&:last-child {
						margin-bottom: 0;
					}

					&:hover {
						background: #31415d;
					}
				}
			}

			.img_content {
				width: 100%;
				height: 100%;
				background-image: url("../assets/imgs/canvas_bg.png");
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 4px;
				overflow: hidden;

				.loading {
					img {
						width: 500px;
						height: auto;
					}

					p {
						text-align: center;
						font-size: 16px;
						transform: translateY(-80px);
					}
				}

				.default {
					text-align: center;

					p {
						font-size: 12px;
						margin-top: 8px;
					}
				}

				.img {
					width: 450px;
					height: 450px;

					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
			}

			.img_list {
				width: 0;
				overflow: hidden;

				&.show {
					width: 160px;
				}

				&:hover {
					overflow-y: scroll;
					overflow-x: hidden;
				}

				&::-webkit-scrollbar {
					width: 12px;
				}

				&::-webkit-scrollbar-thumb {
					border-radius: 12px;
					border: 4px solid rgba(0, 0, 0, 0);
					box-shadow: 4px 0 0 #1a2232 inset;
				}

				&::-webkit-scrollbar-thumb:hover {
					box-shadow: 4px 0 0 #4a4a4a inset;
				}

				// 滚动条两端按钮
				&::-webkit-scrollbar-button {
					// height: 10px;
				}

				.list {
					margin-left: 16px;

					.item {
						width: 100px;
						height: 100px;
						border: 1.5px solid #4c5b74;
						overflow: hidden;
						border-radius: 4px;
						padding: 3px;
						margin-bottom: 20px;
						background: #141a26;
						cursor: pointer;
						display: flex;
						align-items: center;
						justify-content: center;

						&.active {
							border-color: #424ef2;
						}

						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
							border-radius: 2px;
						}
					}
				}
			}
		}

		.config_box {
			width: 500px;
			height: 100%;
			background: #222c3f;
			position: absolute;
			top: 0;
			right: 0;
			z-index: 2;
			display: flex;
			flex-direction: column;

			.mask_box {
				width: 100%;
				height: 100%;
				background: rgba($color: #000000, $alpha: 0);
				position: absolute;
				top: 0;
				left: 0;
				z-index: 99;
			}

			.params_box {
				flex: 1;
				overflow: hidden;
				padding: 20px 12px;

				&:hover {
					overflow-y: scroll;
					overflow-x: hidden;
					padding-right: 0;
				}

				&::-webkit-scrollbar {
					width: 12px;
				}

				&::-webkit-scrollbar-thumb {
					border-radius: 12px;
					border: 4px solid rgba(0, 0, 0, 0);
					box-shadow: 4px 0 0 #1a2232 inset;
				}

				&::-webkit-scrollbar-thumb:hover {
					box-shadow: 4px 0 0 #4a4a4a inset;
				}

				// 滚动条两端按钮
				&::-webkit-scrollbar-button {
					// height: 10px;
				}

				.label_box {
					font-size: 12px;
					margin-bottom: 20px;

					.label {
						margin-bottom: 20px;
						display: flex;
						align-items: center;
						justify-content: space-between;

						.key_word {
							flex: 1;
							margin-left: 20px;
							text-align: right;

							span {
								margin-left: 15px;
								cursor: pointer;

								&.active {
									color: #3e97ff;
								}

								&:hover {
									color: #3e97ff;
								}
							}
						}
					}

					.desc {
						border: 1px solid #4c5b74;
						border-radius: 4px;
						overflow: hidden;

						.desc_input {
							textarea {
								background: transparent;

								border: none;
								color: #fff;
								font-weight: normal;
								font-size: 12px;
							}
						}

						.desc_content {
							border-top: 1px solid #4c5b74;
							border-bottom: 1px solid #4c5b74;

							.item {
								padding: 6px 10px;
								color: #8895a4;
								cursor: pointer;
								border: 1px solid transparent;

								&:hover {
									background: #38465d;
									color: #c4d9f3;
								}

								&.active {
									border-color: #424ef2;
									background: rgba($color: #424ef2, $alpha: 0.1);
									color: #c4d9f3;
								}
							}
						}

						.desc_button {
							height: 32px;
							display: flex;
							align-items: center;
							justify-content: center;
							cursor: pointer;

							&:hover {
								background: #38465d;
							}

							span {
								margin-left: 4px;
							}
						}
					}

					.list {
						display: flex;
						flex-wrap: wrap;

						.item {
							background: #1a2232;
							border-radius: 4px;
							border: 1px solid transparent;
							margin-right: 10px;
							margin-bottom: 10px;
							display: flex;
							flex-direction: column;
							align-items: center;
							justify-content: center;
							cursor: pointer;

							&:hover {
								border-color: #424ef2;
								background: rgba($color: #424ef2, $alpha: 0.1);
							}

							&.active {
								border-color: #424ef2;
								background: rgba($color: #424ef2, $alpha: 0.1);
							}
						}
					}

					.size {
						.item {
							width: calc((100% - 40px) / 5);
							height: 70px;

							&:nth-child(5n) {
								margin-right: 0;
							}

							.graph {
								width: 20px;
								height: 20px;
								border: 1px solid #c4d9f3;
								background: #222c3f;
								margin-bottom: 4px;
							}
						}
					}

					.style {
						.item {
							position: relative;
							width: calc((100% - 40px) / 5);
							height: 0;
							padding-top: calc((100% - 40px) / 5);
							border-color: #4c5b74;
							overflow: hidden;

							&:nth-child(5n) {
								margin-right: 0;
							}

							.img {
								position: absolute;
								top: 0;
								left: 0;
								width: 100%;
								height: 100%;
								padding: 2px;

								img {
									width: 100%;
									height: 100%;
									object-fit: cover;
									border-radius: 2px;
								}

								p {
									width: 100%;
									margin-bottom: 0;
									position: absolute;
									bottom: 0;
									left: 0;
									z-index: 1;
									background: rgba($color: #000000, $alpha: 0.5);
									backdrop-filter: blur(5px);
									text-align: center;
									font-size: 12px;
								}
							}
						}
					}

					.fillet {
						.item {
							width: calc((100% - 40px) / 5);
							height: 32px;
							border-radius: 32px;

							&:nth-child(5n) {
								margin-right: 0;
							}
						}
					}

					.count {
						.item {
							width: 32px;
							height: 32px;
						}
					}
				}
			}

			.button_box {
				height: 70px;
				display: flex;
				align-items: center;
				justify-content: center;

				.button {
					width: 300px;
					height: 46px;
					border-radius: 46px;
					border: none;
					background: linear-gradient(270deg, #ff4196 0%, #5c85ff 100%), linear-gradient(270deg, #ff4196 0%, #934bc4 49%, #2055f4 100%);
					position: relative;

					&:hover {
						background: linear-gradient(270deg, rgba($color: #ff4196, $alpha: 0.5) 0%, rgba($color: #5c85ff, $alpha: 0.5) 100%), linear-gradient(270deg, rgba($color: #ff4196, $alpha: 0.5) 0%, rgba($color: #934bc4, $alpha: 0.5) 49%, rgba($color: #2055f4, $alpha: 0.5) 100%);
					}
				}
			}
		}
	}
}
</style>
